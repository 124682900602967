import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Process from "../components/process"
import InfoWindow from "../components/infoWindow"

//import InfoSection from ""

const more = [
  <div>
    <ul>
      <li> Trash Removal </li>
      <li> Sweeping and Mopping </li>
      <li> Carpet Cleaning</li>
      <li> Kitchen and Lounge Cleanup</li>
      <li> Clean Light Fixtures and baseboards</li>
      <li> Facility Management</li>
      <li> Window Washing</li>
      <li> Mowing lawns</li>
      <li> Weeding gardens</li>
      <li> Pruning trees & bushes</li>
      <li> Removing graffiti</li>
      <li> Cleaning steps & walk ways</li>
      <li> Cleaning glass & windows</li>
      <li> Chewing gum removal</li>
      <li> Pressure washing</li>
      <li> Gutter cleans</li>
      <li> Cleaning car parks & communal areas</li>
    </ul>
    <p>Contact us to learn more.</p>
  </div>,
  <div>
    <ul>
      <li> A short professional analysis </li>
      <li> All relevant photographs</li>
      <li> Date of next needed services </li>
      <li> Cost of next needed services </li>
    </ul>
    Contact us for more information.
    <p></p>
  </div>,
  <div>
    <ul>
      <li> Deoderisers </li>
      <li> FREE Periodic Inspections </li>
      <li> Service Reports </li>
      <li> 24/7 Call-outs </li>
      <li> and more... </li>
    </ul>
    Contact us to customise the service or recieve a demo service report.
    <p></p>
  </div>,
]

const InspectionReporting = (
  <div>
    <h2> Strata maintenance cleaning.</h2>
    <i></i>
    <div>
      <h4>Customised to your needs</h4>
      We work hard to customize a janitorial maintenance program that is
      tailored to the requirements of your building.
      <h4>Made easy for you</h4>
      We do all the work so you don’t have to. Our goal is to provide you with a
      quality transparent service that wrks around you so you can concentrate on
      your work.
      <h4>First-time discount.</h4> Unsure if you're ready to make the switch?
      For a major first-time discount{" "}
      <a href="tel:0413231255">call us on 0413 231 255 </a>
    </div>
  </div>
)
const RegularService = (
  <div>
    <h2> FREE Inspection Reporting</h2>
    <i></i>
    <p>
      <h4> Regular inspections: </h4> Stay ahead of residential complaints and
      costly damages by opting into our FREE regular reporting programme. Our
      team periodically inspects your site and quotes services as they are
      needed. If no services are needed a free follow-up inspection is scheduled
      <h4> Once-off inspection: </h4> Unsure of your needs? Book a once-off free
      inspection.
      <h4>Personal reports</h4>
      After each service, a service report is sent conveniently to your email
      for your records (opt in).
      <br />
    </p>
  </div>
)
const ExtraMile = (
  <div>
    <h2>We go the extra mile</h2>
    <p>
      <h4>Innovative: </h4>
      We are always looking to improve our service quality. Ask about how we
      provide customers just like you with excellent service.
      <br />
      <h4> Competitive pricing: </h4> Our competitors are often over-priced.
      Call us to negotiate.
      <br />
      <h4> Streamlined: </h4> Opt into our FREE inspection programme and never
      worry about it again. We inspect for free on a regular basis and quote
      services as needed.
    </p>
  </div>
)

const ChuteUnblocking = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "home-clean-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "strata-cleaning-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "strata-cleaning-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout style={{ height: "80vh" }}>
      <SEO
        description="Our professional team will clean and maintain your strata community areas alongside all your chute related problems."
        lang="en"
        meta=""
        title="Strata Cleaning | ChuteOff | Brisbane, Gold Coast, Sunshine Coast"
      />
      <section id="top">
        <InfoWindow
          id="chute-cleaning"
          title={<>MAINTAIN YOUR STRATA COMMUNITY</>}
          subtitle="Our professional team will clean and maintain your strata community areas in Brisbane, Gold Coast, and the Sunshine Coast!."
          panes={[InspectionReporting, RegularService, ExtraMile]}
          buttons={["CONTACT ON-CALL STAFF", "BOOK SERVICE", "CUSTOM JOB"]}
          button_subtitles={[
            "Not sure what you need? Talk to an expert",
            "After every service you receive a FREE personalised service report.",

            "Request a custom job tailored to your needs",
          ]}
          images={[
            data.image1.childImageSharp.fluid,
            data.image2.childImageSharp.fluid,
            data.image3.childImageSharp.fluid,
          ]}
          more={more}
          expansion_title={[
            "What is involved in a standard professional home clean?",
            "What is included in the inspection report?",
            "Does ChuteOff offer me any extras?",
          ]}
        />
      </section>
      <Process />
    </Layout>
  )
}

export default ChuteUnblocking
